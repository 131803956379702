import type { NextPage } from 'next';

import Preloader from 'components/preloader';
import { ColumnView, LoginContent, Sidebar } from 'components/auth';
import { useCheckProvidedOrg } from 'hooks/useCheckProvidedOrg';

const Login: NextPage = () => {
  const { name } = useCheckProvidedOrg();

  if (!name) return <Preloader />;

  return (
    <ColumnView left={<Sidebar content="login" />} right={<LoginContent />} />
  );
};

export default Login;
